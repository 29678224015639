import axios from 'axios';
import React, { useState } from 'react';
import Calendar from 'react-calendar';
import { useQuery } from 'react-query';
import config from '../../util/config';
import Stats from './Stats';
import TableEntry from './TableEntry';

const fetchDayData = async (key, date) => {
  console.log('date', date);

  const { data } = await axios.get(
    `${config.serverUrl}/api/daydata?date=${date}`,
    {
      withCredentials: true
    }
  );
  return data;
};

const ListeneningHistory = () => {
  const [date, setDate] = useState(new Date());
  const { status, data, error } = useQuery(
    ['dayData', date.toISOString().substring(0, 10)],
    fetchDayData
  );

  console.log('date State', date);
  console.log({ status, data, error });

  if (status === 'loading') return <div>Loading...</div>;
  if (error) return <pre>{JSON.stringify(error)}</pre>;

  const changeDate = newDate => setDate(newDate);

  return (
    <div className="mx-8">
      <div className="my-4">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {date.toLocaleDateString()}
        </h3>
        <Calendar value={date} onChange={changeDate} showWeekNumbers={false} />
        <Stats entries={data} />
      </div>
      <div className="flex flex-col">
        <div className="-my-2 py-2 pb-16 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg">
            <table className="min-w-full">
              <thead className="font-bold text-gray-800 tracking-wider text leading-4 uppercase">
                <tr>
                  <th className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left">
                    Date
                  </th>
                  <th className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left">
                    Track
                  </th>
                  <th className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left">
                    Artist
                  </th>
                  <th className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left">
                    Album
                  </th>
                  <th className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left">
                    Context
                  </th>
                  <th className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left">
                    Duration
                  </th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((entry, i) => {
                    const odd = i % 2 === 1;
                    return (
                      <TableEntry key={entry.id} entry={entry} odd={odd} />
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListeneningHistory;
