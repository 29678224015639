import React, { useEffect, useState } from 'react';
import Header from './components/Header';
import Login from './components/Login';
import './css/tailwind.css';
import ListeneningHistory from './routes/ListeningHistory';
import config from './util/config';

const App = () => {
  const [loggedIn, setLoggedIn] = useState(null);

  useEffect(() => {
    if (loggedIn === null) {
      fetch(`${config.serverUrl}/api/checkLoggedIn`, {
        method: 'GET',
        credentials: 'include'
      })
        .then(res => res.json())
        .then(value => {
          setLoggedIn(value.loggedIn);
        });
    }
  }, [loggedIn]);

  if (loggedIn === null) return <p>loading....</p>;

  return (
    <div>
      <Header />
      <div>{loggedIn === false ? <Login /> : <ListeneningHistory />}</div>
    </div>
  );
};

export default App;
