import PropTypes from 'prop-types';

const entryShape = {
  albumname: PropTypes.string,
  albumuri: PropTypes.string,
  contexttype: PropTypes.string,
  contexturi: PropTypes.string,
  durationseconds: PropTypes.number.isRequired,
  explicit: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  timestamp: PropTypes.string.isRequired,
  trackid: PropTypes.string.isRequired,
  trackname: PropTypes.string.isRequired,
  trackuri: PropTypes.string.isRequired
};

export default entryShape;
