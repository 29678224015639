import PropTypes from 'prop-types';
import React from 'react';
import entryShape from './entryShape';
import StatDisplay from './StatDisplay';

const getTimeString = entries => {
  const secondsSum = entries.reduce(
    (prev, cur) => prev + cur.durationseconds,
    0
  );
  const minutesSum = Math.round(secondsSum / 60);

  const hours = Math.floor(minutesSum / 60);
  const minutes = Math.round(minutesSum % 60);

  return hours > 0 ? `${hours}h ${minutes}m` : `${minutes}m`;
};

const getMostListenedTrack = entries => {
  const countIndex = entries.reduce((acc, curr) => {
    if (acc[curr.trackid] === undefined) {
      acc[curr.trackid] = 1;
    } else {
      acc[curr.trackid] += 1;
    }

    return acc;
  }, {});

  const highestId = Object.keys(countIndex).reduce((a, b) =>
    countIndex[a] > countIndex[b] ? a : b
  );

  const count = countIndex[highestId];
  const track = entries.find(entry => entry.trackid === highestId);

  return { track: track.trackname, count };
};

const Stats = ({ entries }) => {
  if (entries.length === 0) {
    return <div>No data...</div>;
  }

  const trackCount = entries.length;
  const timeString = getTimeString(entries);
  const { track, count } = getMostListenedTrack(entries);

  return (
    <div>
      <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
        <StatDisplay description="Total Tracks" value={trackCount.toString()} />
        <StatDisplay description="Time" value={timeString} />
        <StatDisplay
          description="Most played track"
          value={track}
          additional={`(${count} plays)`}
        />
      </div>
    </div>
  );
};

Stats.propTypes = {
  entries: PropTypes.arrayOf(PropTypes.shape(entryShape)).isRequired
};

export default Stats;
