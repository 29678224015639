import PropTypes from 'prop-types';
import React from 'react';
import entryShape from './entryShape';

const TableEntry = ({ entry, odd }) => {
  const time = new Date(entry.timestamp).toLocaleTimeString();

  return (
    <tr className={`${odd ? 'bg-gray-50' : 'bg-white'} text-gray-700`}>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5">{time}</td>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5">
        {entry.trackname}
      </td>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5">...</td>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5">
        {entry.albumname}
      </td>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5">
        {entry.contexttype}
      </td>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5">
        {`${Math.floor(entry.durationseconds / 60)}m ${(
          entry.durationseconds % 60
        )
          .toString()
          .padStart(2, '0')}s`}
      </td>
    </tr>
  );
};

TableEntry.propTypes = {
  entry: PropTypes.shape(entryShape).isRequired,
  odd: PropTypes.bool.isRequired
};

export default TableEntry;
