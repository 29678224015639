import React, { useEffect, useState } from 'react';
import config from '../util/config';

const Login = () => {
  const [loginUrl, setLoginUrl] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    if (loginUrl) return;

    fetch(`${config.serverUrl}/spotify/url`)
      .then(response => {
        return response.json();
      })
      .then(data => {
        console.log(data);
        setLoginUrl(data.url);
      })
      .catch(e => {
        console.log(e);
        setError(e.message);
      });
  });

  if (error) return <pre>Error: {error}</pre>;
  if (!loginUrl) return <div>Loading...</div>;

  return (
    <div className="text-center">
      <h2 className="text-2xl text-gray-700 mb-8">
        Login to access statistics
      </h2>
      <a
        className="bg-orange-300 py-2 px-4 rounded-lg shadow hover:bg-orange-200 focus:bg-orange-400 focus:shadow-md focus:outline-none"
        href={loginUrl || '#'}
      >
        <span className="text-lg uppercase text-orange-800">Login</span>
      </a>
    </div>
  );
};

export default Login;
