import PropTypes from 'prop-types';
import React from 'react';

const StatDisplay = ({ description, value, additional }) => {
  return (
    <div className="bg-white overflow-hidden shadow rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <dl>
          <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
            {description}
          </dt>
          <dd className="mt-1 text-3xl leading-9 font-semibold text-gray-900">
            {value}
            {additional ? (
              <span className="ml-3 text-3xl font-normal text-gray-600">
                {additional}
              </span>
            ) : null}
          </dd>
        </dl>
      </div>
    </div>
  );
};

StatDisplay.propTypes = {
  description: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  additional: PropTypes.string
};

StatDisplay.defaultProps = {
  additional: undefined
};

export default StatDisplay;
